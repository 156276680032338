import React from 'react';

import OndemandVideo from '@mui/icons-material/OndemandVideo';
import { Box } from '@mui/material';

import DemoButton from '../../common/button/DemoButton';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const pageHeadSubTextPadding = { xs: 7, sm: 8, md: 8, lg: 0 };

const buttonBodyStyle = {
  textAlign: styles.alignStyleCCCC,
  pb: 3,
};

const PageHeadLeftSide = ({ data }) => {
  const { title, subTitle, buttonName } = data;

  const CTAbuttonName = (
    <MainText
      textVariant="h4"
      value={buttonName}
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MainText
        textVariant="h1"
        textAlignment={styles.alignStyleCCCC}
        value={title}
        marginY={styles.allZero}
        paddingX={{
          xs: 5,
          sm: 5,
          md: 5,
          lg: 0,
        }}
      />

      <SubText
        variant="subtitle1"
        textAlignment={styles.alignStyleCCCC}
        paddingX={pageHeadSubTextPadding}
        value={subTitle}
      />
      <Box sx={buttonBodyStyle}>
        <DemoButton
          buttonName={CTAbuttonName}
          startIcon={<OndemandVideo style={{ fontSize: 30 }} />}
        />
      </Box>
    </Box>
  );
};

export default PageHeadLeftSide;
