import React, { useContext } from 'react';

import VerifiedIcon from '@mui/icons-material/Verified';
import { Box } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';
import Body from '../../common/body/Body';
import ScrollButton from '../../common/button/ScrollButton';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import AnimatedPlayIcon from '../CommonComps/AnimatedPlayIcon';

const bulletPointStyle = {
  color: 'secondary.main',
  fontSize: 40,
  height: '100%',
  textAlign: 'center',
};

const buttonStyle = {
  textAlign: styles.alignStyleCCCC,
  my: 3,
};
const EventsOfferingsCTA = ({ data }) => {
  const { title, body, howItWorks, buttonName } = data;

  const button = (
    <MainText
      textVariant="h4"
      value={buttonName}
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  return (
    <Body
      paddingY={styles.allTwenty}
      backgroundImage="middle"
      backgroundColor="rgb(249, 250, 251)"
      addPadding
    >
      <MainText textVariant="h2" value={title} />

      <SubText
        variant="subtitle1"
        textAlignment={styles.alignStyleLLLL}
        value={<FanGoTextProcessor text={body} color="#ffffff" />}
      />

      <Box sx={{ textAlign: 'center' }}>
        {howItWorks.map((point) => (
          <Box key={point.children[0]._key} sx={styles.bulletPointBodyStyle}>
            <VerifiedIcon sx={bulletPointStyle} />

            <SubText
              variant="subtitle1"
              marginY={styles.allTwo}
              textAlignment={styles.alignStyleLLLL}
              ml={styles.allTwo}
              value={point.children[0].text}
            />
          </Box>
        ))}

        <Box sx={buttonStyle}>
          <ScrollButton buttonName={button} />
        </Box>
      </Box>
    </Body>
  );
};

export default EventsOfferingsCTA;
