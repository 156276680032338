import React from 'react';

import { Box } from '@mui/material';

import styles from '../common/styling/styles';
import { Testimonial } from './';
import BrandLogo from './BrandLogo';

const TestimonialList = (array) => {
  const testimonialList = array.map((client, index) => {
    const { image } = client;

    const { asset, altText } = image;
    const { gatsbyImageData } = asset;
    const url = asset.url;

    return (
      <Box
        key={index}
        sx={{
          display: styles.displayStyleBBGG,
          gridTemplateColumns: '4fr 5fr',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '500px',
          width: '100%',
          height: '100%',
        }}
      >
        <BrandLogo src={gatsbyImageData} alt={altText} />

        <Testimonial data={client} />
      </Box>
    );
  });

  return testimonialList;
};

export default TestimonialList;
