import React from 'react';

import { Box, Divider } from '@mui/material';

import Body from '../../common/body/Body';
import { FanGoGridCarousel } from '../../common/carousel';
import styles from '../../common/styling/styles';
import TestimonialList from '../../testimonial/TestimonialListGenerator';

const TestimonialCarousel = ({ data }) => {
  const { testimonies } = data;

  const testimonialsArray = TestimonialList(testimonies);

  return (
    <Body paddingY={styles.allTen}>
      <Divider sx={{ mb: 10 }} />

      <Box
        sx={{
          backgroundImage:
            'radial-gradient(100% 100% at 100% 0%, rgb(4, 32, 119) 0%, rgb(20, 24, 85) 100%)',
          borderRadius: '16px',
          boxShadow: '0 3px 10px rgb(140, 152, 164, 0.3)',
        }}
      >
        <FanGoGridCarousel
          array={testimonialsArray}
          rows={1}
          cols={1}
          mobileBreakpoint={10}
        />
      </Box>

      <Divider sx={{ mt: 15 }} />
    </Body>
  );
};

export default TestimonialCarousel;
