import { Box } from '@mui/material';
import React from 'react';
import Body from '../../common/body/Body';
import { FanGoGridCarousel } from '../../common/carousel';
import { MainText } from '../../common/texts';
import { brandIconImages } from '../../brands';
import styles from '../../common/styling/styles';

const FeatureBrandIcons = ({ data }) => {
  const iconImages = brandIconImages(data.images);
  const carouselBodyStyle = { textAlign: 'center', pt: 5, pb: 2 };

  return (
    <Body paddingY={styles.allTen}>
      <Box>
        <MainText
          textVariant="h2"
          fontColor={styles.deepGreen}
          value={data.title}
        />
        <Box sx={carouselBodyStyle}>
          <FanGoGridCarousel
            array={iconImages}
            cols={3}
            rows={1}
            gap={2}
            mobileBreakpoint={10}
            bottom="-30%"
            showDots={false}
            responsiveLayout={[
              {
                breakpoint: 1200,
                cols: 2,
              },
              {
                breakpoint: 990,
                cols: 1,
              },
            ]}
          />
        </Box>
      </Box>
    </Body>
  );
};

export default FeatureBrandIcons;
