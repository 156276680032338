import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Box } from '@mui/material';

import styles from '../common/styling/styles';

const BrandLogo = ({ src, alt }) => {
  return (
    <Box
      sx={{
        borderRadius: { xs: '0px', md: '0px 500px 500px 0px' },
        backgroundColor: styles.lightGrey,
        width: '100%',
        height: { xs: 300, md: '100%' },
        p: { xs: 10, md: 15 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GatsbyImage image={src} alt={alt || ''} objectFit="fill" />
      {/* <img
        alt={alt}
        src={src}
        style={{ objectFit: "contain", height: "100%", maxWidth: "100%" }}
      /> */}
    </Box>
  );
};

export default BrandLogo;
