import React from 'react';

import Body from '../../common/body/Body';
import HowWeBuildContent from './HowWeBuildContent';

const HowWeBuild = ({ data }) => {
  return (
    <Body backgroundImage="middle" backgroundColor="#F7FAFF">
      <HowWeBuildContent data={data} />
    </Body>
  );
};

export default HowWeBuild;
