import React, { useContext } from 'react';

import { Box } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';
import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import PageHeadLeftSide from './PageHeadLeftSide';
import PageHeadRightSide from './PageHeadRightSide';

const pageHeadBodyStyle = {
  display: styles.displayStyleBBBG,
  gridTemplateColumns: { lg: '3fr 2fr' },
  gap: 2,
};

const centerAlignedBox = {
  textAlign: 'center',
};

const PageHead = ({ data }) => {
  const { playButtonActionText } = data;

  const { setShowExplainerVideo } = useContext(DataContext);

  const handlePlayButtonClick = () => {
    setShowExplainerVideo(true);
  };

  return (
    <Body
      backgroundColor="transparent"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      noAnimation
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PageHeadLeftSide data={data} />
        {/* <Box sx={pageHeadBodyStyle}>
          <PageHeadLeftSide data={data} />

          <PageHeadRightSide
            playButtonActionText={playButtonActionText}
            handlePlayButtonClick={handlePlayButtonClick}
          />
        </Box> */}
      </Box>
    </Body>
  );
};

export default PageHead;
