import React from 'react';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineSeparator,
} from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box } from '@mui/material';

import FanGoIconGenerator from '../../common/icon/FanGoIconGenerator';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';

const pointBodyStyle = {
  boxShadow: '0 0 0 2px #2D9B83 ',
  borderRadius: 100,
  webkitBorderRadius: 100,
  color: styles.deepGreen,
  width: { xs: 60, md: 70 },
  height: { xs: 60, md: 70 },
  p: 1.5,
  textAlign: 'center',
  m: '20px auto',
  ':hover': {
    backgroundColor: styles.deepGreen,
    color: styles.white,
    outline: '#2D9B83 solid 0px',
    boxShadow: '-24px 24px 72px -8px rgba(145, 158, 171, 0.24)',
  },
};

const FanGoTimeline = ({ data }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        mt: 5,
      }}
    >
      {data.map((feature) => (
        <TimelineItem key={feature._key}>
          <TimelineSeparator>
            <Box sx={pointBodyStyle}>
              {FanGoIconGenerator({ iconName: feature.Icon })}
            </Box>

            <TimelineConnector
              sx={{
                backgroundColor: styles.deepGreen,
                '&.MuiTimelineConnector-root': {
                  flexGrow: 1,
                },
              }}
            />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              '&.MuiTimelineContent-root': {
                pl: '16px',
              },
            }}
          >
            <MainText
              textVariant="h4"
              value={feature.header}
              paddingTop={styles.allFour}
              textAlignment={styles.alignStyleLLLL}
            />
            <Box
              sx={{
                color: styles.white,
                textAlign: styles.alignStyleLLJJ,
                fontSize: 18,
                mt: 4,
              }}
            >
              <FanGoTextProcessor text={feature.body} color="#ffffff" />
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default FanGoTimeline;
