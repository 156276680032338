import { Box } from "@mui/material";
import React from "react";
import { SubText } from "../common/texts";
import styles from "../common/styling/styles";
import FanGoButton from "../common/button/FanGoButton";

const Testimonial = ({ data }) => {
  const { name, jobTitle, company, testimony, caseStudy } = data;

  return (
    <Box
      sx={{
        p: { xs: "20px 50px", md: "20px 70px 20px 0px" },
      }}
    >
      <SubText
        variant="h4"
        value={`“${testimony}”`}
        marginY={styles.allZero}
        marginBottom={styles.allThree}
        textAlignment={styles.alignStyleCCLL}
        fontWeight={500}
      />

      <SubText
        variant="h6"
        fontWeight={600}
        marginY={styles.allZero}
        value={name}
        textAlignment={styles.alignStyleCCLL}
      />
      <SubText
        variant="body1"
        marginY={styles.allZero}
        marginBottom={styles.allThree}
        value={`${jobTitle} @${company}`}
        textAlignment={styles.alignStyleCCLL}
      />

      {caseStudy && (
        <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
          <FanGoButton
            buttonName="Case Study"
            link={caseStudy}
            formatLink={false}
          />
        </Box>
      )}
    </Box>
  );
};

export default Testimonial;
