import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const VideoBackground = ({
  imgData,
  videoID,
  maxHeight = '',
  textContent,
  videoMinHeight,
  shadow = 0.4,
  mobileScreen = false,
}) => {
  const classes = useStyles();
  const url = `https://vimeo.com/995354577`;
  const image = getImage(imgData.asset.gatsbyImageData);
  const bgImage = convertToBgImage(image);
  const [isReady, setIsReady] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);

  return (
    <Box
      sx={{
        maxHeight,
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          right: mobileScreen ? 250 : 0,
          aspectRatio: '16/9',
          minHeight: videoMinHeight,
        }}
      >
        <BackgroundImage
          style={{
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
          }}
          {...bgImage}
        >
          <ReactPlayer
            onReady={() => {
              setIsReady(true);
            }}
            style={{ display: isReady && !isBuffering ? 'block' : 'none' }}
            onBuffer={() => setIsBuffering(true)}
            onBufferEnd={() => setIsBuffering(false)}
            playbackRate={0.8}
            id="MyId"
            width="100%"
            height="100%"
            controls={false}
            playing
            muted
            loop
            url={url}
            playsinline={true}
          />
        </BackgroundImage>
        {/* <video
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            position: 'absolute',
          }}
          loop
          autoPlay
          muted
          playsInline
          poster={imgData.asset.gatsbyImageData}
          onCanPlayThrough={() => setVideoPlaying(true)}
          // style={{ height: '100%', width: '100%' }}
        >
          <source src={homepageVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </Box>
      <Box
        sx={{
          height: '100%',
          maxHeight: maxHeight,
          width: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundColor: 'white',
          top: 0,
          backgroundColor: `rgba(30, 30, 30, ${shadow})`,
        }}
      >
        {textContent}
      </Box>
    </Box>
  );
};

export default VideoBackground;
